import api from '@/services/api'

export default {
  getDrivers() {
    return api.get('/drivers')
  },

  approveDriver(id) {
    return api.post(`/drivers/approve/${id}`)
  },
}