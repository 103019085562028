<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DriverService from '@/services/driver'
import { VMoney } from 'v-money'

export default {
  page: {
    title: "Entregadores",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  directives: { money: VMoney },
  data() {
    return {
      title: "Entregadores",
      drivers: [],
      driverSelected: {},
      fields: [
        { key: 'image', sortable: false, label: 'Foto' },
        { key: 'name', sortable: false, label: 'Nome' },
        { key: 'phoneNumber', sortable: false, label: 'Telefone' },
        { key: 'email', sortable: false, label: 'E-Mail' },
        { key: 'status', sortable: false, label: 'Status' },
        { key: 'actions', label: 'Ações' },
      ],
      modalError: '',
      coupon: {
        code: '',
        type: 'value',
      },
      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 20,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    rows() {
      return this.drivers.length;
    },
    startIndex() {
      return (this.currentPage * this.perPage) - this.perPage + 1;
    },
    endIndex() {
      const endIndex = (this.currentPage * this.perPage);
      if (this.drivers.length < endIndex) {
        return this.drivers.length;
      }

      return endIndex;
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load(loader = null) {
      if (!loader) {
        loader = this.$loading.show();
      }

      DriverService.getDrivers().then(res => {
        // eslint-disable-next-line no-console
        console.log(res.data);
        this.drivers = res.data.drivers;

        loader.hide();
      }, err => {
        // eslint-disable-next-line no-console
        console.log(err);
      })
    },
    approveDriver() {
      this.$bvModal.hide('modal-confirmation');

      let loader = this.$loading.show();

      DriverService.approveDriver(this.driverSelected._id).then(() => {
        this.load(loader);
        this.$toast.open('Entregador aprovado');
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro. Tente novamente.');
      })
    },
    seeData(data) {
      this.driverSelected = data;
      this.$bvModal.show('modal-driver');
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body"> 
            <!-- <div class="row mb-2">
              <div class="col-sm-12">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="newCoupon">
                    <i class="mdi mdi-plus mr-1"></i> Novo Cupom
                  </button>
                </div>
              </div>
            </div> -->

            <div class="table-responsive table-centered mb-0">
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="drivers"
                :fields="fields"
                responsive="sm"
                head-variant="light"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template v-slot:cell(image)="row">
                  <!-- <div class="team">
                    <a href="javascript: void(0);" class="team-member d-inline-block">
                      <img
                        src="@/assets/images/users/avatar-7.jpg"
                        class="rounded-circle avatar-xs m-1"
                        alt
                      />
                    </a>
                  </div> -->

                  <div class="team">
                    <a href="javascript: void(0);" class="team-member d-inline-block">
                      <div class="avatar-xs">
                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">{{row.item.name.substring(0, 1).toUpperCase()}}</span>
                      </div>
                    </a>
                  </div>
                </template>

                <!-- <template v-slot:cell(name)="row">
                  <h5 class="text-truncate font-size-14">
                    <a href="javascript: void(0);" class="text-dark">{{row.item.name}}</a>
                  </h5>
                  <p class="text-muted mb-0 mt-0">{{row.item.phoneNumber}}</p>
                </template> -->

                <template v-slot:cell(status)="row">
                  <span
                    class="badge badge-pill badge-soft-success font-size-12"
                    :class=" {
                      'badge-soft-warning': !row.item.approved,
                      'badge-soft-success': (row.item.approved && row.item.status === 'online'),
                      'badge-soft-danger': (row.item.approved && row.item.status === 'offline'),
                    }"
                  >{{!row.item.approved ? 'Em Análise' : (row.item.status === 'online' ? 'Online' : 'Offline')}}</span>
                </template>   

                <template v-slot:cell(actions)="row">
                  <b-dropdown class="card-drop" variant="white" dropleft toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>

                    <b-dropdown-item @click="seeData(row.item)">
                      <i class="fas fa-folder-open text-warning mr-2 mt-2"></i>
                      Dados Cadastrais
                    </b-dropdown-item>

                    <b-dropdown-item v-if="!row.item.approved" v-b-modal.modal-confirmation @click="driverSelected = row.item">
                      <i class="fas fa-check text-success mr-2 mt-2"></i>
                      Aprovar Cadastro
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="row justify-content-md-between align-items-md-center mt-4">
          <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{rows}}</div>

          <div class="col-xl-5">
            <div class="text-md-right float-xl-right mt-2 pagination-rounded">
              <b-pagination
                v-model="currentPage" 
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-driver"
      size="lg"
      scrollable
      title="Dados do Entregador"
      title-class="font-18"
      hide-footer
    >
      <div class="row">
        <div class="col">
          <p><span class="font-weight-bold">Nome:</span> {{driverSelected.name}}</p>
          <p><span class="font-weight-bold">Telefone:</span> {{driverSelected.phoneNumber}}</p>
          <p><span class="font-weight-bold">CPF:</span> {{driverSelected.cpf}}</p>
          <p><span class="font-weight-bold">E-mail:</span> {{driverSelected.email}}</p>
        </div>

        <div class="col" v-if="driverSelected.bankData">
          <p><span class="font-weight-bold">Banco:</span> {{driverSelected.bankData.bankCode}}</p>
          <p><span class="font-weight-bold">Agência:</span> {{driverSelected.bankData.agency}}</p>
          <p><span class="font-weight-bold">Número Conta:</span> {{driverSelected.bankData.account}}</p>
          <p><span class="font-weight-bold">Titular:</span> {{driverSelected.bankData.cpf}} / {{driverSelected.bankData.name}}</p>
        </div>
      </div>

      <p class="font-weight-bold">Imagens do Cadastro</p>

      <div class="register-images">
        <img :src="driverSelected.registerPicture" />
        <img :src="driverSelected.driverLicense" />
      </div>
    </b-modal>

    <b-modal
      id="modal-confirmation"
      title="Confirmar Aprovação"
      title-class="font-18"
      centered
    >
      <p>Confirma a aprovação do cadastro do entregador <b>{{driverSelected.name}}</b>?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-confirmation')">Cancelar</b-btn>
        <b-btn variant="primary" @click="approveDriver">Aprovar</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .register-images {
    display: flex;
    flex-direction: column;

    img {
      height: 200px;
      object-fit: contain;
      margin-bottom: 10px;
    }
  }
</style>